<template>
  <div>
    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area ptb--60 bg_image bg_image--27"
      data-black-overlay="7"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center blog-single-page-title pt--100">
              <h1 class="heading-title theme-gradient">
                Activation de l'application
              </h1>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <ActivateApp></ActivateApp>

    <v-col cols="12">
      <div class="text-center section-title">
        <p style="font-size: 12px;padding-bottom: 10px">
          <a href="/user/dashboard">passer cette étape</a>
        </p>
        <p style="font-size: 10px;padding-bottom: 10px">
          Si vous utilisez l'application Mes amis de confiance sur Google Home,
          vous autorisez Google à contrôler vos appareils
        </p>
      </div>
    </v-col>
    <!-- End Blog Area  -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ActivateApp from "@/views2/user/widget/ActivateApp";

export default {
  components: { ActivateApp },
  computed: {
    ...mapGetters(["showMenu"])
  }
};
</script>
