<template>
  <!-- Start Blog Area  -->
  <div class="rn-blog-area ptb--60 bg_color--5">
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="text-center section-title">
            <p class="description">
              Souhaitez-vous associer votre compte Mes amis de confiance avec
              votre assistant?
              <br />
            </p>
          </div>
        </v-col>
      </v-row>
      <template>
        <v-row align="center" justify="center">
          <v-col
            lg="4"
            md="4"
            sm="6"
            cols="12"
            class="text-center section-title"
          >
            <div class="im_box mt--30">
              <div class="thumbnail">
                <img
                  class="w-100"
                  :src="
                    require('../../../assets/images/pricing/assistance_login_alexa.jpg')
                  "
                  alt="connection Alexa"
                />
              </div>
              <div class="content">
                <div class="inner">
                  <div class="content_heading">
                    <h4 class="heading-title">
                      J'active le Skill sur mon enceinte Alexa
                    </h4>
                    <div style="text-align: center">
                      <v-btn
                        type="button"
                        class="btn-custom mx-5 px-10"
                        variant="outline-primary"
                        style="background-color: white !important; color: red !important"
                        :loading="isLoading"
                        :disabled="isLoading"
                        @click="goToAmazon()"
                      >
                        OUI
                        <template v-slot:isLoading>
                          <span class="custom-loader">
                            <v-icon light>mdi-cached</v-icon>
                          </span>
                        </template>
                      </v-btn>
                    </div>
                  </div>
                  <div class="content_footer">
                    Vous rencontrez un problème pour associer votre compte ?
                    <br />
                    Accédez à notre FAQ en cliquant
                    <router-link to="/alexa">
                      <span style="color:white; text-decoration:underline"
                        >ici
                      </span></router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col lg="4" md="4" sm="6" cols="12">
            <div class="im_box mt--30" style="text-align: center">
              <div class="thumbnail">
                <router-link to="/blog-details">
                  <img
                    class="w-100"
                    :src="
                      require('../../../assets/images/pricing/assistance_login_google.jpg')
                    "
                    alt="connection Google"
                  />
                </router-link>
              </div>
              <div class="content">
                <div class="inner">
                  <div class="content_heading">
                    <h4 class="heading-title">
                      J'active l'action sur mon Assistant Google.
                    </h4>

                    <div id="gSignInWrapper" style="text-align: center">
                      <v-btn
                        type="button"
                        class="btn-custom mx-5 px-10"
                        variant="outline-primary"
                        style="background-color: white !important; color: red !important"
                        :loading="isLoading"
                        :disabled="isLoading"
                        @click="goToGoogle()"
                      >
                        OUI
                        <template v-slot:isLoading>
                          <span class="custom-loader">
                            <v-icon light>mdi-cached</v-icon>
                          </span>
                        </template>
                      </v-btn>
                    </div>
                  </div>
                  <div class="content_footer">
                    Vous rencontrez un problème pour vous connecter ? <br />
                    Accédez à notre FAQ en cliquant
                    <router-link to="/google">
                      <span style="color:white; text-decoration:underline"
                        >ici
                      </span></router-link
                    >
                  </div>
                </div>
                <router-link
                  class="transparent_link"
                  to="/blog-details"
                ></router-link>
              </div>
            </div>
          </v-col>

          <!-- End Blog Area  -->
        </v-row>
      </template>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AUTH from "@aws-amplify/auth";
import API from "@aws-amplify/api";

export default {
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    goToGoogle() {
      document.location.href =
        "https://assistant.google.com/services/a/uid/0000002fc831fd88?hl=fr_fr";
    },
    async goToAmazon() {
      this.isLoading = true;
      const userInfo = await AUTH.currentUserInfo();
      const identity = JSON.parse(userInfo.attributes.identities);
      const state = await API.get(
        "teleassistancesenioruserapi321",
        "/alexa/state"
      );

      document.location.href =
        "https://" +
        this.$Amplify.Auth.configure().oauth.domain +
        "/oauth2/authorize?redirect_uri=https://" +
        document.domain +
        "/user/alexalink&response_type=code&client_id=" +
        process.env.VUE_APP_CLIENT_ID +
        "&scope=phone%20email%20openid%20profile%20aws.cognito.signin.user.admin&state=" +
        state.state +
        "&identity_provider=" +
        identity[0].providerType;
    }
  },
  computed: {
    ...mapGetters(["showMenu"])
  }
};
</script>

<style type="text/css">
#customBtn,
#customLWABtn {
  display: inline-block;
  background: white;
  color: #444;
  width: 190px;
  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
}

#customAppleBtn {
  align-content: center;
  display: inline-block;
  background: white;
  color: #444;

  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
}

#customBtn:hover,
#customLWABtn:hover,
#customAppleBtn:hover {
  cursor: pointer;
}

span.label {
  font-family: serif;
  font-weight: normal;
}

span.iconGoogle {
  background: url("../../../assets/images/icons/google-normal.png") transparent
    5px 50% no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}

span.iconApple {
  background: url("../../../assets/images/icons/apple-black.png") transparent
    5px no-repeat;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}

span.buttonText {
  display: inline-block;
  vertical-align: middle;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  font-weight: bold;
  /* Use the Roboto font that is loaded in the <head> */
  font-family: "Roboto", sans-serif;
}

.content_heading h4.heading-title {
  color: white;
  text-align: center;
  margin-bottom: 50px !important;
}

.im_box .content .inner {
  bottom: 75px;
}

.content_footer {
  bottom: 15px !important;
  color: white;
  font-size: small;
  text-align: center;
}
</style>
